<template>
  <div>
    <b-card v-if="haveNextEvaluationDate">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h1 class="py-2">
            {{ userEvaluationStatus.employee_name }}
          </h1>
          <span>Next Evaluation Date : </span>
          <b-badge>{{ userEvaluationStatus.next_evaluation_date }} </b-badge>
        </div>
        <div>
          <b-btn
            variant="primary"
            @click="commentEvaluation"
          >
            Comment Evaluation
          </b-btn>
        </div>
      </div>
    </b-card>
    <b-card v-else>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h1 class="py-2">
            {{ userEvaluationStatus.employee_name }}
          </h1>
          <span>Status : </span>
          <b-badge>{{ userEvaluationStatus.status }} </b-badge>
        </div>
        <div>
          <b-btn
            variant="primary"
            @click="commentEvaluation"
          >
            Comment Evaluation
          </b-btn>
        </div>
      </div>
    </b-card>

    <general-table
      :api-url="apiUrl"
      :columns="columns"
      :type="type"
    />
  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    GeneralTable,
  },
  data() {
    return {
      APIURL: `performance-evaluation/${this.$route.params.id}`,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      userEvaluationStatus: {},
      haveNextEvaluationDate: false,
      columns: [
        { key: 'from', label: 'From' },
        { key: 'to', label: 'To' },
        { key: 'average_score', label: 'Average Score' },
        { key: 'final_rate', label: 'Final Rate' },
      ],
    }
  },

  computed: {

    apiUrl() {
      let base = `performance-evaluation/${this.$route.params.id}?`

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {
    this.getEvaluationStatus()
  },

  methods: {
    getEvaluationStatus() {
      axios.get(`/evaluation-status/${this.$route.params.id}`).then(res => {
        this.userEvaluationStatus = decryptData(res.data.payload).data
        this.haveNextEvaluationDate = Object.keys(this.userEvaluationStatus).includes('next_evaluation_date')
        // ? this.haveNextEvaluationDate = true : this.haveNextEvaluationDate = false
      })
    },
    commentEvaluation() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You want to comment this evaluation!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, do it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .post(`/evaluations/${this.$route.params.id}/start-evaluation`)
            .then(() => {
              this.$swal('Commented!', 'Commented Successfully.', 'success')
            })
        }
      })
    },
  },
}
</script>

  <style></style>
